<template>
  <div class="base">
    <div class="base_head" :style="'background: url(' + provinceInfo.companyImage + ') no-repeat'">
      <div class="base_head_name">{{ provinceInfo.companyName }}</div>
    </div>
    <!-- baseCompanyDetail_bg -->
    <div class="base_intr">
      <div class="base_intr_title">公司介绍</div>
      <div class="base_intr_content">{{ provinceInfo.companyDesc }}</div>
    </div>
    <div class="base_child_info">
      <div class="base_child_info_title">
        <div class="base_child_info_title_cn">新闻列表</div>
        <div class="base_child_info_title_en">NEW LIST</div>
      </div>
      <div class="base_child_info_news">
        <div class="base_child_info_news_bg">
          <el-carousel height="320px" indicator-position="none" ref="newsImgCarousel" arrow="never"
            @change="changeNewsImgCarousel" class="newsImgCarousel">
            <el-carousel-item v-for="(item, index) in newsList1" :key="item.newsId + 'newsImg'">
              <img src="../../static/newImg/img_loading.png" class="img_loading" v-show="imgSuffix"/>
              <img :src="item.newsCover + imgSuffix" width="586px" @click="toDetail(item, 22)" />
            </el-carousel-item>
          </el-carousel>
          <div class="base_child_info_news_bg_bottom">
            <div class="base_child_info_news_bg_bottom_title" v-if="newsList1.length"
              @click="toDetail(newsList1[childNewsImageIndex], 22)">
              {{ newsList1[childNewsImageIndex].newsTitle }}</div>
            <div>
              <div :class="childNewsImageIndex == index
                ? 'base_child_info_news_bg_bottom_Index active'
                : 'base_child_info_news_bg_bottom_Index'
                " v-for="(i, index) in newsList1" @click="changeNewsImageIndex(index)" :key="index + 'newsImg1'">
                {{ index + 1 }}
              </div>
            </div>
          </div>
        </div>
        <div class="base_child_info_news_left">
          <div class="base_child_info_news_left_title">
            <div :class="childNewsIndex == 33
              ? 'base_child_info_news_left_title_name active'
              : 'base_child_info_news_left_title_name'
              " @mouseenter="changeNewsIndex(33)">
              最新报道
            </div>
            <div :class="childNewsIndex == 34
              ? 'base_child_info_news_left_title_name active'
              : 'base_child_info_news_left_title_name'
              " @mouseenter="changeNewsIndex(34)">
              新闻资讯
            </div>
          </div>
          <div class="base_child_info_news_left_list moveTop" @click="toDetail(item, 22)" v-for="item in newsList"
            :key="'newsList' + item.newsId">
            <div class="base_child_info_news_left_list_title">
              <div class="base_child_info_news_left_list_title_point"></div>{{ item.newsTitle }}
            </div>
            <div class="base_child_info_news_left_list_day">{{ item.publishTime }}</div>
          </div>
        </div>
      </div>
      <div class="base_banner">
        <img
          src="https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-06-05/e4e8ea1d446c4ba1bcb5e76dbbd163ed.png"
          width="1200px" />
      </div>
      <div class="base_news1">
        <div class="base_news1_left_img" v-if="newsList1.length > 0" @click="toDetail(newsList1[0], 22)">
          <img :src="newsList1[0].newsCover + imgSuffix" width="100%" />
        </div>
        <div class="base_news1_right_img" v-if="newsList2.length > 0" @click="toDetail(newsList2[0], 22)">
          <img :src="newsList2[0].newsCover + imgSuffix" width="100%" />
        </div>
        <div class="base_news1_left_list">
          <div class="base_news1_left_list_title moveTop" @click="toDetail(item, 22)" v-for="(item) in newsList1"
            :key="'newsList' + item.newsId">
            <div class="base_news1_left_list_title_point"></div>{{ item.newsTitle }}
          </div>
        </div>
        <div class="base_news1_right_list">
          <div class="base_news1_right_list_title moveTop" @click="toDetail(item, 22)" v-for="(item) in newsList2"
            :key="'newsList' + item.newsId">
            <div class="base_news1_right_list_title_point"></div>{{ item.newsTitle }}
          </div>
        </div>
      </div>
      <div class="base_child_info_title" v-show="childProjectList.length">
        <div class="base_child_info_title_cn">项目列表</div>
        <div class="base_child_info_title_en">PROJECT LIST</div>
      </div>
      <div class="base_child_info_project" v-show="childProjectList.length > 1">
        <div class="base_child_info_project_item" v-for="item in childProjectList" :key="item.projectId"
          @click="toProject(item)">
          <!-- {{ item.projectName }} -->
          <div class="base_child_info_project_item_img">
            <img :src="item.projectImage + imgSuffix" width="590px" />
          </div>
          <div class="base_child_info_project_item_tips">
            <div class="base_child_info_project_item_tips_left">项目名称：{{ item.projectName }}</div>
            <div class="base_child_info_project_item_tips_left">项目时间：{{ item.projectDate | fomatterDate }}</div>
            <div class="base_child_info_project_item_tips_left">产品介绍：<br>
              <div class="base_child_info_project_item_tips_right">{{ item.projectDesc }}</div>
            </div>
            <div class="base_child_info_project_item_tips_more">
              了解详情<img src="../../static/newImg/位图9.png" width="16px" height="16px" />
            </div>
          </div>
        </div>
      </div>
      <div class="base_child_info_project" v-show="childProjectList.length == 1">
        <div class="base_child_info_project_item_only" v-for="item in childProjectList" :key="item.projectId"
          @click="toProject(item)">
          <!-- {{ item.projectName }} -->
          <div class="base_child_info_project_item_img">
            <img :src="item.projectImage + imgSuffix" width="590px" />
          </div>
          <div class="base_child_info_project_item_tips">
            <div class="base_child_info_project_item_tips_left">项目名称：{{ item.projectName }}</div>
            <div class="base_child_info_project_item_tips_left">项目时间：{{ item.projectDate | fomatterDate }}</div>
            <div class="base_child_info_project_item_tips_left">产品介绍：<br>
              <div class="base_child_info_project_item_tips_right">{{ item.projectDesc }}</div>
            </div>
            <div class="base_child_info_project_item_tips_more">
              了解详情<img src="../../static/newImg/位图9.png" width="16px" height="16px" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper } from "swiper";
import { getCompanyList, getPojectList, getPojectDetail } from '@/api/index.js'
import axios from "axios";
export default {
  name: "Base",
  data() {
    return {
      imgSuffixTime: null,
      imgSuffix: '?x-oss-process=image/resize,m_lfit,w_300',
      provinceInfo: {
        companyId: 0,
        companyImage: '',
        companyName: '',
        city: []
      },
      cityIndex: 0,
      newsList: [],
      newsList1: [],
      newsList2: [],
      childNewsList: [],
      newsImageIndex: 0,
      childNewsImageIndex: 0,
      newsIndex: 0,
      childNewsIndex: 33,
      childProjectList: [],
      container1: null,
      container2: null,
      pCompanyName: '',
      pCompanyId: ''
    };
  },
  filters: {
    fomatterDate: (val) => {
      if (val.length > 11) {
        val = val.replace('-', '年')
        val = val.replace('-', '月')
        val = val.replace('-', '年')
        val = val.replace('-', '月')
        val = val.replace(',', '日 - ')
        val += '日'
      } else {
        val = val.replace('-', '年')
        val = val.replace('-', '月')
        val += '日'
      }
      return val
    }
  },
  computed: {
  },
  mounted() {
    this.imgSuffixTime = setTimeout(() => {
      this.imgSuffix = ''
    }, 4000)
    window.scroll(0, 0);
    if (this.$route.query.companyId) {
      this.companyId = this.$route.query.companyId
      this.pCompanyName = this.$route.query.pCompanyName
      this.pCompanyId = this.$route.query.pCompanyId
    }
    this.getCompanyList()
    this.getCompanyInfo();
    this.changeNewsIndex(33);
    this.changeNewsIndex1(33);
    this.changeNewsIndex2(34);
    if (this.$refs.newsImgCarousel) {
      this.slideBanner()
    }
  },
  methods: {
    getCompanyInfo() {
      axios.get(
        `https://admin.zoneyu.cn/cms/api/app/company/${this.companyId}`
      )
        .then(async (res) => {
          this.provinceInfo = res.data.data
        });
    },
    slideBanner() {
      //选中的轮播图
      var box = document.querySelector('.newsImgCarousel .el-carousel__container');
      var startPoint = 0;
      var stopPoint = 0;
      //重置坐标
      var resetPoint = function () {
        startPoint = 0;
        stopPoint = 0;
      }
      //手指按下
      box.addEventListener("touchstart", function (e) {
        //手指点击位置的X坐标
        startPoint = e.changedTouches[0].pageX;
      });
      //手指滑动
      box.addEventListener("touchmove", function (e) {
        //手指滑动后终点位置X的坐标
        stopPoint = e.changedTouches[0].pageX;
      });
      //当手指抬起的时候，判断图片滚动离左右的距离
      let that = this
      box.addEventListener("touchend", function (e) {
        if (stopPoint == 0 || startPoint - stopPoint == 0) {
          resetPoint();
          return;
        }
        if (startPoint - stopPoint > 0) {
          resetPoint();
          that.$refs.newsImgCarousel.next();
          return;
        }
        if (startPoint - stopPoint < 0) {
          resetPoint();
          that.$refs.newsImgCarousel.prev();
          return;
        }
      });
    },
    changeNewsImageIndex(index) {
      this.childNewsImageIndex = index;
      this.$refs.newsImgCarousel.setActiveItem(index);
    },
    changeNewsImgCarousel(index) {
      this.childNewsImageIndex = index;
    },
    toDetail(item) {
      this.$router.push({
        path: "/companyNewsListDetail",
        query: { id: item.newsId, name1: '首页', name2: this.pCompanyName, id2: this.pCompanyId, name3: item.companyName, id3: item.companyId },
      });
    },
    toProject(item) {
      getPojectDetail(item.projectId).then((res) => {
        const resource = res.data.base.resource.length
        const productDTOList = res.data.productDTOList.length
        const landDTOList = res.data.landDTOList.length
        if (resource && productDTOList && landDTOList) {
          this.$router.push({
            path: "/projectDetail",
            query: { id: item.projectId },
          });
        }
      })
    },
    changeNewsIndex(index) {
      this.childNewsIndex = index;
      axios.get(
        `https://admin.zoneyu.cn/cms/api/app/news/list?current=1&size=5&websiteCategoryId=${index}&companyId=${this.companyId}`
      )
        .then(async (res) => {
          this.newsList = res.data.data.list;
        });
    },
    changeNewsIndex1(index) {
      axios.get(
        `https://admin.zoneyu.cn/cms/api/app/news/list?current=1&size=8&websiteCategoryId=${index}&companyId=${this.companyId}`
      )
        .then(async (res) => {
          this.newsList1 = res.data.data.list;
          this.newsList1.splice(0, 4)
        });
    },
    changeNewsIndex2(index) {
      axios.get(
        `https://admin.zoneyu.cn/cms/api/app/news/list?current=1&size=10&websiteCategoryId=${index}&companyId=${this.companyId}`
      )
        .then(async (res) => {
          this.newsList2 = res.data.data.list;
          this.newsList2.splice(0, 4)
        });
    },
    getCompanyList() {
      getCompanyList({ current: 1, size: 4, pid: this.companyId }).then((res) => {
        this.provinceInfo.city = res.data.list
        if (this.provinceInfo.city.length) {
          this.getProjectList()
        }
        this.$nextTick(() => {
          const that = this
          this.container1 = new Swiper(".swiper-container1", {
            slidesPerView: 3,
            spaceBetween: 30,
            centeredSlides: true,
            loop: true
          });
          this.container2 = new Swiper(".swiper-container2", {
            on: {
              slideNextTransitionStart: function () {
                that.container1.slideNext()
                if (that.cityIndex == that.provinceInfo.city.length - 1) {
                  that.cityIndex = 0
                } else {
                  that.cityIndex += 1
                }
                that.getProjectList()
              },
              slidePrevTransitionStart: function () {
                that.container1.slidePrev()
                if (that.cityIndex == 0) {
                  that.cityIndex = that.provinceInfo.city.length - 1
                } else {
                  that.cityIndex -= 1
                }
                that.getProjectList()
              },
            },
            initialSlide: 1,
            slidesPerView: 3,
            loop: true,
            effect: 'coverflow',
            grabCursor: true,
            centeredSlides: true,
            coverflowEffect: {
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: true,
            },
          });

        })
      })
    },
    getProjectList() {
      axios.get(
        `https://admin.zoneyu.cn/cms/api/app/project/list?current=1&size=1000&companyId=${this.companyId}`
      )
        .then(async (res) => {
          this.childProjectList = res.data.data.list;
        });
    }
  },
  watch: {
    $route() {
      if (this.$route.query.companyId) {
        this.companyId = this.$route.query.companyId
        this.getCompanyList()
        this.getCompanyInfo();
        this.changeNewsIndex(33);
        this.changeNewsIndex1(33);
        this.changeNewsIndex2(34);
      }
    },
  },
  beforeDestroy() {
    clearTimeout(this.imgSuffixTime)
  },
};
</script>

<style scoped lang="scss">
.base {
  width: 100%;
  background-color: #fff;

  .base_head {
    position: relative;
    background-size: 100% !important;
    width: 100%;
    height: 550px;
    padding-top: 30px;

    .base_bg {
      width: 100%;
    }


    .base_head_name {
      width: 100%;
      text-align: center;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 70px;
      font-weight: bold;
      color: #FFFFFF;
      letter-spacing: 8px;
      font-family: 楷体;
    }
  }

  .base_head::before {
    content: " ";
    position: absolute;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.52) 0%, rgba(0, 0, 0, 0.74) 100%);
    width: 100%;
    height: 580px;
    top: 0;
    left: 0;
  }

  .base_intr {
    width: 100%;
    height: 370px;
    background: url('https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-06-05/7f1bfee957f94af491d46157e4828b0a.png') no-repeat;
    background-size: cover;
    text-align: center;
    padding-top: 190px;

    .base_intr_title {
      font-size: 40px;
      font-weight: 500;
      color: #000000;
      line-height: 31px;
      width: 1000px;
      margin: 0 auto;
    }

    .base_intr_content {
      width: 1000px;
      margin: 0 auto;
      font-size: 16px;
      font-weight: 400;
      color: #252525;
      line-height: 36px;
      text-align: left;
      margin-top: 40px;
    }
  }

  .base_newsImg {
    background: url('https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-06-05/a49edfe4de974fcc9d0b9314aec91b5b.png') no-repeat;
    background-size: cover;
    height: 800px;

    .base_news {
      display: flex;
      width: 1200px;
      margin: 50px auto;

      .base_news_bg {
        width: 586px;
        position: relative;

        img {
          transform: scale(1.4);
        }

        .base_news_bg_bottom {
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: 2;
          color: #fff;
          padding: 0 16px;
          font-size: 14px;
          font-weight: bold;
          width: calc(100% - 32px);
          height: 40px;
          line-height: 40px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: #0000008a;

          .base_news_bg_bottom_title {
            cursor: pointer;
            width: 70%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .base_news_bg_bottom_Index {
            float: left;
            color: #354A32;
            width: 20px;
            height: 18px;
            position: relative;
            line-height: 18px;
            border: 1px solid #FFFFFF;
            text-align: center;
            margin-right: 5px;
            cursor: pointer;
            background: #ffffff9a;
          }

          .base_news_bg_bottom_Index:hover {
            color: #fff;
            background: #354A32;
            border: 1px solid #354A32;
          }

          .active {
            color: #fff;
            background: #354A32;
            border: 1px solid #FFFFFF;
          }
        }
      }

      .base_news_left {
        margin-left: 40px;
        width: calc(100% - 626px);

        .base_news_left_title {
          display: flex;
          width: 100%;
          border-bottom: 2px solid #EFEFEF;
          margin-bottom: 40px;

          .base_news_left_title_name {
            margin-left: 10px;
            padding: 0 34px;
            height: 69px;
            line-height: 69px;
            text-align: center;
            border: 1px solid #ddd;
            border-bottom: 0;
            background: #fff;
            color: #000;
            cursor: pointer;
            font-size: 23px;
          }

          .base_news_left_title_name:first-child {
            margin-left: 0;
          }

          .active {
            background: #354A32;
            color: #fff;
            transition: all 0.3s ease-in-out 0s;
          }
        }

        .base_news_left_list {
          cursor: pointer;
          width: 100%;
          display: flex;
          line-height: 46px;
          height: 46px;
          justify-content: space-between;

          .base_news_left_list_title {
            width: 85%;
            font-size: 16px;
            font-weight: 600;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            /* 当文本溢出时显示省略号 */
            .base_news_left_list_title_point {
              width: 4px;
              height: 4px;
              background: #8C8F92;
              opacity: 0.31;
              display: inline-block;
              margin-right: 8px;
              vertical-align: middle;
            }
          }

          .base_news_left_list_day {
            font-size: 13px;
            color: #0000008c;
          }
        }
      }
    }

  }

  .base_banner {
    width: 1200px;
    margin: 0 auto 40px auto;
  }

  .base_news1 {
    display: flex;
    width: 1200px;
    margin: 50px auto;
    justify-content: space-between;
    flex-wrap: wrap;

    .base_news1_left_img,
    .base_news1_right_img {
      cursor: pointer;
      width: 48%;
    }

    .base_news1_left_list,
    .base_news1_right_list {
      cursor: pointer;
      width: 48%;
      display: flex;
      line-height: 46px;
      justify-content: space-between;
      flex-wrap: wrap;

      .base_news1_left_list_title,
      .base_news1_right_list_title {
        width: 100%;
        font-size: 16px;
        font-weight: 600;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        /* 当文本溢出时显示省略号 */
        .base_news1_left_list_title_point,
        .base_news1_right_list_title_point {
          width: 4px;
          height: 4px;
          background: #8C8F92;
          opacity: 0.31;
          display: inline-block;
          margin-right: 8px;
          vertical-align: middle;
        }
      }
    }
  }

  .base_child_top {
    background: url('https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-06-05/7a71a092964e4be9b1c3a50510fa440d.png') no-repeat;
    background-size: cover;
    height: 974px;
    text-align: center;
    margin-top: 90px;
    position: relative;

    .base_child_top_title_cn {
      font-size: 31px;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 47px;
      padding-top: 90px;
    }

    .base_child_top_title_en {
      font-size: 18px;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 27px;
      letter-spacing: 2px;
      margin-top: 22px;
    }

    .base_child_top_tips {
      width: 100%;
      height: 167px;
      background: rgba(0, 0, 0, .6);
      position: absolute;
      bottom: 0;
      left: 0;

      .base_child_top_tips_img {
        margin-top: 18px;
        position: relative;
      }

      .base_child_top_tips_line::before {
        content: "";
        background-color: #fff;
        height: 5px;
        width: 120px;
        position: absolute;
        top: 56px;
        left: calc(50% - 60px);
      }


      .base_child_top_tips_line {
        width: 100%;
        height: 1px;
        background: rgba(255, 255, 255, .5);
        margin-top: 14px;
      }

      .base_child_top_tips_name {
        font-size: 24px;
        color: #FFFFFF;
        line-height: 108px;
        letter-spacing: 2px;
      }
    }

    .base_child_top_img {
      width: 1200px;
      margin: 40px auto;

      .base_child_top_img_out {
        .base_child_top_img_out_img {
          position: relative;
          text-align: center;
          width: 620px;
          margin: 0 auto;

          div {
            width: 100%;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            font-size: 34px;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 31px;
            letter-spacing: 4px;
            text-shadow: 0px 2px 40px #354A32;
          }
        }

        .base_child_top_img_out_img::after {
          content: " ";
          background-color: #00000010;
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
        }

        .base_child_top_img_out_tips {
          padding: 25px;
          background-color: #fff;
          width: 570px;
          height: 190px;
          margin: 0 auto;
          text-align: left;

          .base_child_top_img_out_tips_title {
            font-size: 20px;
            font-weight: 900;
            color: #354A32;
            line-height: 31px;
          }

          .base_child_top_img_out_tips_desc {
            margin: 15px 0;
            font-size: 16px;
            font-weight: 400;
            color: #6B766A;
            line-height: 27px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }

  .base_child_tips {
    width: 100%;
    text-align: center;
    margin-bottom: 88px;
  }

  .base_child_info {
    width: 1200px;
    margin: 60px auto;

    .base_child_info_title {
      text-align: center;
      margin-top: 90px;

      .base_child_info_title_cn {
        font-size: 31px;
        font-weight: bold;
        color: #000000;
        line-height: 47px;
      }

      .base_child_info_title_en {
        margin-top: 8px;
        font-size: 18px;
        font-weight: 400;
        color: #979797;
        line-height: 27px;
        letter-spacing: 2px;
      }
    }

    .base_child_info_news {
      display: flex;
      width: 1200px;
      margin: 50px auto;

      .base_child_info_news_bg {
        width: 586px;
        position: relative;

        img {
          transform: scale(1.6);
        }

        .base_child_info_news_bg_bottom {
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: 2;
          color: #fff;
          padding: 0 16px;
          font-size: 14px;
          font-weight: bold;
          width: calc(100% - 32px);
          height: 40px;
          line-height: 40px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: #0000008a;

          .base_child_info_news_bg_bottom_title {
            cursor: pointer;
            width: 70%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .base_child_info_news_bg_bottom_Index {
            float: left;
            color: #354A32;
            width: 20px;
            height: 18px;
            position: relative;
            line-height: 18px;
            border: 1px solid #FFFFFF;
            text-align: center;
            margin-right: 5px;
            cursor: pointer;
            background: #ffffff9a;
          }

          .base_child_info_news_bg_bottom_Index:hover {
            color: #fff;
            background: #354A32;
            border: 1px solid #354A32;
          }

          .active {
            color: #fff;
            background: #354A32;
            border: 1px solid #FFFFFF;
          }
        }
      }

      .base_child_info_news_left {
        margin-left: 40px;
        width: calc(100% - 626px);

        .base_child_info_news_left_title {
          display: flex;
          width: 100%;
          border-bottom: 2px solid #EFEFEF;
          margin-bottom: 20px;

          .base_child_info_news_left_title_name {
            margin-left: 10px;
            padding: 0 34px;
            height: 60px;
            line-height: 60px;
            text-align: center;
            border: 1px solid #ddd;
            border-bottom: 0;
            background: #fff;
            color: #000;
            cursor: pointer;
            font-size: 23px;
          }

          .base_child_info_news_left_title_name:first-child {
            margin-left: 0;
          }

          .active {
            background: #354A32;
            color: #fff;
            transition: all 0.3s ease-in-out 0s;
          }
        }

        .base_child_info_news_left_list {
          cursor: pointer;
          width: 100%;
          display: flex;
          line-height: 46px;
          height: 46px;
          justify-content: space-between;

          .base_child_info_news_left_list_title {
            width: 85%;
            font-size: 16px;
            font-weight: 600;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            /* 当文本溢出时显示省略号 */
            .base_child_info_news_left_list_title_point {
              width: 4px;
              height: 4px;
              background: #8C8F92;
              opacity: 0.31;
              display: inline-block;
              margin-right: 8px;
              vertical-align: middle;
            }
          }

          .base_child_info_news_left_list_day {
            font-size: 13px;
            color: #0000008c;
          }
        }
      }
    }

    .base_child_info_project {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .base_child_info_project_item {
        cursor: pointer;
        width: 590px;
        margin-top: 20px;

        .base_child_info_project_item_img {
          width: 590px;
          height: 320px;
          overflow: hidden;

          img {
            transform: scale(1.3);
          }
        }

        .base_child_info_project_item_tips {
          padding: 24px;
          background-color: #fff;
          box-shadow: 0px 2px 11px 0px rgba(53, 74, 50, 0.07);

          .base_child_info_project_item_tips_left {
            font-size: 20px;
            font-weight: 900;
            color: #354A32;
            line-height: 40px;
          }

          .base_child_info_project_item_tips_right {
            margin-top: 12px;
            font-size: 16px;
            font-weight: 400;
            color: #6B766A;
            line-height: 27px;
            height: 85px;
            overflow: hidden;
          }

          .base_child_info_project_item_tips_more {
            width: 500px;
            margin-top: 30px;
            font-size: 12px;
            font-weight: 600;
            color: #595959;
            line-height: 20px;
            display: flex;
            align-items: center;


            img {
              vertical-align: middle;
              margin-left: 10px;
              transition: all 0.5s ease-out;
            }
          }
        }
      }

      .base_child_info_project_item_only {
        display: flex;
        margin-top: 20px;

        .base_child_info_project_item_img {
          width: 590px;
          overflow: hidden;

          img {
            display: block;
            transform: scale(1.2);
          }
        }

        .base_child_info_project_item_tips {
          width: calc(100% - 590px - 48px);
          padding: 24px;
          background-color: #fff;
          box-shadow: 0px 2px 11px 0px rgba(53, 74, 50, 0.07);

          .base_child_info_project_item_tips_left {
            font-size: 20px;
            font-weight: 900;
            color: #354A32;
            line-height: 40px;
          }

          .base_child_info_project_item_tips_right {
            margin-top: 12px;
            font-size: 16px;
            font-weight: 400;
            color: #6B766A;
            line-height: 27px;
            height: 85px;
            overflow: hidden;
          }

          .base_child_info_project_item_tips_more {
            width: 500px;
            margin-top: 30px;
            font-size: 12px;
            font-weight: 600;
            color: #595959;
            line-height: 20px;
            display: flex;
            align-items: center;

            img {
              vertical-align: middle;
              margin-left: 10px;
              transition: all 0.5s ease-out;
            }
          }
        }
      }
    }
  }

}

.swiper-container1,
.swiper-container2 {
  width: 100%;
  height: 100%;
  overflow: hidden;

  .swiper-slide {
    overflow: hidden;
  }

  .swiper-slide-next {
    font-weight: bold;
  }
}

.swiper-container2 {
  .swiper-slide-active {
    width: 708px !important;
    z-index: 9;
    transform: scale(1.25);
  }

  .swiper-slide-prev {
    width: 246px !important;
    z-index: 8;
    opacity: 0.56;
    transform: scale(0.8);
  }

  .swiper-slide-next {
    width: 246px !important;
    z-index: 8;
    opacity: 0.56;
    transform: scale(0.8);
  }
}
</style>
